import React from 'react'
import styled from 'styled-components'
import { BlueBackgroundHeader } from '../components/form'
import SEO from '../components/seo'

const Text = styled.div`
width: 100%;
height: 100%;
color: white;
font-size: 24px;
display: flex;
justify-content: center;
align-items: center;
height: 400px;
`

const NotFoundPage = () => (
  <BlueBackgroundHeader>
    <SEO title="404: Not found" Content={{ SiteMeta: {} }} />
    <Text>Sorry, the page you requested was not found.</Text>
  </BlueBackgroundHeader>

)

export default NotFoundPage
